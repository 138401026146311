import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { HeaderCard } from "../../components/cards/HeaderCard";
import { useParams } from "react-router-dom";
import { Navigation } from "../../lib";
import { ChevronRight } from "@mui/icons-material";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";

const mapUrl = "/svg/map.svg";

const HeaderSection = ({
  title,
  buttonTitle,
  path,
}: {
  title: string;
  buttonTitle: string;
  path: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        mt: 2,
        width: "96%",
        mr: "auto",
        ml: "auto",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Button
        variant="text"
        color="primary"
        onClick={() => Navigation.go(path)}
        endIcon={<ChevronRight />}
      >
        {buttonTitle}
      </Button>
    </Box>
  );
};
const Fade = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Box
      onClick={() => {
        if (onClick) onClick();
      }}
      sx={{
        width: "96%",
        margin: "auto",
        position: "relative",
        bgcolor: "white",
        borderRadius: "8px",
      }}
    >
      {children}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: "8px",
          backgroundColor: "transparent",
          background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.71) 81.97%, #ffffff 100%);`,
        }}
      />
    </Box>
  );
};

const CardPage = () => {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  return (
    <>
      <Container maxWidth="md">
        <Grid container>
          <Grid sm={12} sx={{ flexGrow: 1 }}>
            <Box sx={{ width: "96%", margin: "auto", borderRadius: "9px" }}>
              <HeaderCard />
            </Box>
          </Grid>
          <Grid sm={12} sx={{ flexGrow: 1 }}>
            <HeaderSection
              title="Brands included"
              buttonTitle="View all"
              path={"/admin/cards/" + id + "/brands"}
            />
            <Fade
              onClick={() => Navigation.go("/admin/cards/" + id + "/brands")}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  bgcolor: "white",
                  p: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.71) 81.97%, #FFFFFF 100%)`,
                  maxHeight: "132px",
                  overflow: "hidden",
                }}
              >
                {data?.merchantPreviews.map((preview) => {
                  const A = preview.logo ? (
                    <img style={{ borderRadius: "50%" }} src={preview.logo} />
                  ) : (
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {preview.display_name[0]}
                    </Avatar>
                  );
                  return (
                    <Chip
                      size="small"
                      key={preview.id}
                      label={preview.display_name}
                      avatar={A}
                      sx={{ flexGrow: 1, maxWidth: "31%" }}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            </Fade>
          </Grid>
          <Grid sm={12} sx={{ flexGrow: 1 }}>
            <HeaderSection
              title="Store locations"
              buttonTitle="View Map"
              path={"/admin/cards/" + id + "/map"}
            />
            <Fade onClick={() => Navigation.go("/admin/cards/" + id + "/map")}>
              <Box className="MapStyles SmallMapStyles">
                <img src={mapUrl} />
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CardPage;
