import React, { useEffect } from "react";
import { useHash } from "../../lib";
import { Brand, Offer } from "../../global";

export function useHandleForScrollOfferTab(
  items: Offer | Brand,
  tabsRef: React.RefObject<HTMLDivElement>,
) {
  const [hash, setHash] = useHash();
  useEffect(() => {
    if (!items.length) return;
    const tabsElement = tabsRef.current;
    if (!tabsElement) return;
    const handleScroll = () => {
      const tabsElement = tabsRef.current;
      if (!tabsElement) return;

      const tabsBottom = tabsElement.getBoundingClientRect().bottom;
      const tabsTop = tabsElement.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const isAtBottom = viewportHeight + Math.round(scrollY) >= docHeight - 10; // Adding small threshold

      // Get all category headers
      const headers = items
        .map((offer) => document.getElementById(offer.categoryId.toString()))
        .filter(Boolean) as HTMLElement[];

      if (headers.length === 0) return;

      // If we're at the bottom, find all fully visible sections
      if (isAtBottom) {
        const fullyVisibleHeaders = headers.filter((header) => {
          const rect = header.getBoundingClientRect();
          // Ensure the entire element is within the viewport bounds
          // Adding small margin to account for potential rounding issues
          return (
            rect.top >= tabsBottom - 1 && // Element starts below tabs
            rect.bottom <= viewportHeight + 1 // Element ends before viewport bottom
          );
        });

        if (fullyVisibleHeaders.length > 0) {
          // If current hash is among fully visible headers, keep it
          if (
            hash &&
            fullyVisibleHeaders.some((header) => header.id === hash)
          ) {
            return;
          }
          // Otherwise, select the first fully visible header
          const newActiveId = fullyVisibleHeaders[0].id;
          if (newActiveId !== hash) {
            setHash(newActiveId);
          }
        }
        return;
      }

      // Normal scrolling behavior
      let currentActiveId = null;

      for (let i = 0; i < headers.length; i++) {
        const currentHeader = headers[i];
        const nextHeader = headers[i + 1];
        const headerRect = currentHeader.getBoundingClientRect();

        // If this is the last header
        if (!nextHeader) {
          if (headerRect.top <= tabsBottom) {
            currentActiveId = currentHeader.id;
          }
          break;
        }

        const nextHeaderRect = nextHeader.getBoundingClientRect();

        // Check if current header is visible and in correct position
        if (
          headerRect.top <= tabsBottom &&
          headerRect.bottom > tabsTop &&
          nextHeaderRect.top > tabsBottom
        ) {
          currentActiveId = currentHeader.id;
          break;
        }
      }

      if (currentActiveId && currentActiveId !== hash) {
        setHash(currentActiveId);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, [items, setHash, hash]);
}
