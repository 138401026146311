import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  ParamsSerializerOptions,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { API_BASE_URL, VITE_PERSIST_KEY } from "../../config";
import { auth, AuthState } from "./state";
import { timeoutAsync } from "../../lib";
import { AppThunk, User } from "../types";
import { uiActions } from "../ui/actions";
import { store } from "../../state";

const { actions } = auth;

interface AuthResponse {
  user?: { id: number; roles: string[]; email: string };
  token?: string;
  id?: number;
}

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.delete(url, config));
  },
  download(_url: string, _config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.post<T>(url, data, config),
    );
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.put<T>(url, data, config),
    );
  },
};

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { token } = authResponseData;
      authStateFromResponse = {
        token,
      };
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  loadUserValues(user?: User, roles?: string[]): AppThunk {
    return async (dispatch) => {
      if (user) {
        const authState: AuthState = {
          roles,
          user,
        };
        dispatch(actions.setAuthState(authState));
      }
    };
  },
  socialLogin(values: {
    credential: string;
    sign_in_type: "google" | "microsoft";
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/social-login`, values, {
          baseURL: API_BASE_URL,
          headers: { "Content-Type": "application/json" },
        });
        dispatch(uiActions.setLoading(false));
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
        return true;
      } catch (_e) {
        dispatch(uiActions.showError());
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  multiFactorAuth(values: { mfaCode: string; rememberMe: boolean }): AppThunk {
    return async (dispatch) => {
      //await Promise.resolve();
      const {
        data: { user, token, message },
      } = await authClient.post(`/auth/multi-factor-auth`, values, {
        baseURL: API_BASE_URL,
        headers: { "Content-Type": "application/json" },
      });
      if (message !== null && message !== undefined) {
        dispatch(uiActions.showError(message));
      }
      if (user.mfa_required === false) {
        dispatch(uiActions.setLoading(false));
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
          requiresMfa: user.mfa_required,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
      }
    };
  },
  resendMfaCode(type = "sms"): AppThunk {
    return async () => {
      await Promise.resolve();
      const method = type === "email" ? "?type=email" : "";
      await authClient.get(`/auth/resend-mfa-code${method}`, {
        baseURL: API_BASE_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout();
    };
  },
};

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}
let refreshWait = 100;
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: (_apiClient: AxiosInstance) => Promise<AxiosResponse<T>>,
  retrying = false,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  const promiseWithCatch = promise(apiClient).catch((err) => {
    return err.response;
  });
  const res = await promiseWithCatch;
  if (error) {
    console.error({ error, res });
  }
  if (res.status && res.status === 401) {
    const dispatch: any = store.dispatch;
    if (!retrying) {
      refreshWait += refreshWait + 200;
      console.warn("Calling refresh token", refreshWait);
      if (refreshWait > 30000) {
        console.error("refreshWait too high");
        return res;
      }
      const response = await authClient.get("/auth/refresh-token");
      if (response.status === 200) {
        await dispatch(authActions.load(response.data));
        await timeoutAsync(refreshWait);
        return handleAuthResponse(promise, true);
      } else {
        // if refresh token is also expired
        await logout();
      }
    } else {
      await logout();
      redirectToLogin();
    }
  }
  return res;
}

export function redirectToLogin() {
  if (!window.location.search.includes("after=")) {
    window.location.replace(
      "/auth/login?after=" +
        encodeURIComponent(window.location.pathname + window.location.search),
    );
  }
}

export async function logout() {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${VITE_PERSIST_KEY}`);
  await timeoutAsync(500);
  await authClient.get("/logout").catch(console.error);
  store.dispatch(actions.setAuthState(undefined));
  await timeoutAsync(500);
  redirectToLogin();
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
const serializeParams: ParamsSerializerOptions = {
  encode: (params: { [x: string]: any; toString?: any }) => {
    if (params instanceof URLSearchParams) {
      return params.toString();
    }
    const formattedParams: Record<string, any> = {};
    const keys = Object.keys(params);
    const { length } = keys;
    for (let i = 0; i < length; i++) {
      const key = keys[i];
      let value = params[key];
      if (value === null || value === undefined) {
        continue;
      }
      if (Object.prototype.toString.call(value) === "[object Date]") {
        // Format Dates...
        value = value.toISOString();
      } else if (value !== null && typeof value === "object") {
        // Format objects and arrays...
        value = JSON.stringify(value);
      }
      formattedParams[key] = value;
    }
    // URLSearchParams does not handle arrays...
    // return new URLSearchParams(formattedParams).toString();
    return qs.stringify(formattedParams);
  },
};
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
