import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
  List,
  ListSubheader,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import { useHash } from "../../lib";
import { TabContext } from "@mui/lab";
import { useMemo, useRef, useState } from "react";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";
import { OfferCard } from "../../components/cards/OfferCard";
import { Offer } from "../../global";
import { useHandleForScrollOfferTab } from "../../components/hooks/useHandleScroll";

const OffersPage = () => {
  const [hash] = useHash();
  const tabsRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [onlyAvailable, _setOnlyAvailable] = useState(true);

  const offers = useMemo(() => {
    if (!data?.categorizedMerchants) return [];
    const o: Offer = data?.categorizedMerchants.reduce((acc: Offer, curr) => {
      const offers = curr.merchants.reduce(
        (acc: Offer[number]["offers"], curr) => [
          ...acc,
          ...curr.offers
            .filter((o) => {
              if (onlyAvailable) {
                const expired =
                  new Date(o.custom_expiration || data!.expires_on) <
                  new Date();
                const usedUp =
                  !!o.number_of_uses && o.used_count >= o.number_of_uses;
                return !expired && !usedUp;
              }
              return true;
            })
            .map((o) => ({
              ...o,
              merchantId: curr.merchantId,
              logo: curr.logo,
              display_name: curr.display_name,
            })),
        ],
        [],
      );
      const categorizedOffers = {
        categoryId: curr.categoryId,
        category_name: curr.category_name,
        offers,
      };
      return [...acc, categorizedOffers];
    }, []);
    return o;
  }, [data?.categorizedMerchants, onlyAvailable]);

  const categoryCounts: Record<string, number> = useMemo(() => {
    if (!offers) return {};
    return offers.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.categoryId]: curr.offers.length,
      }),
      {},
    );
  }, [offers]);

  useHandleForScrollOfferTab(offers, tabsRef);

  const handleTabClick = (event: React.SyntheticEvent, newValue: string) => {
    const element = document.createElement("a");
    element.href = `#${newValue}`;
    element.click();
  };

  return (
    <Container maxWidth="md" sx={{ width: "96%", margin: "auto" }}>
      <Grid container>
        <Grid xs={12}>
          <TabContext value={hash || offers[0]?.categoryId.toString() || ""}>
            <Box
              ref={tabsRef}
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                bgcolor: "white",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                width: "100%",
              }}
            >
              <Tabs
                variant="scrollable"
                onChange={handleTabClick}
                value={hash || offers[0]?.categoryId.toString() || ""}
              >
                {offers.map((item) => (
                  <Tab
                    key={item.categoryId}
                    label={`${item.category_name} (${
                      categoryCounts[item.categoryId]
                    })`}
                    value={item.categoryId.toString()}
                  />
                ))}
              </Tabs>
              <Divider sx={{ w: "100%", borderColor: "divider" }} />
            </Box>
            <Box
              sx={{
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                bgcolor: "white",
                p: 1,
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {offers.map((item) => (
                <List
                  key={item.categoryId}
                  id={item.categoryId.toString()}
                  subheader={
                    <ListSubheader
                      disableSticky
                      component="a"
                      href={`#${item.categoryId}`}
                      sx={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        fontWeight="bold"
                        component="span"
                        marginRight={1}
                      >
                        {item.category_name}
                      </Typography>
                      ({item.offers.length} offer
                      {item.offers.length > 1 ? "s" : ""})
                    </ListSubheader>
                  }
                  sx={{ scrollMarginTop: 54, width: 1 }}
                >
                  {item.offers.map((offer) => (
                    <OfferCard
                      key={offer.id}
                      expires_on={data?.expires_on!}
                      {...offer}
                    />
                  ))}
                </List>
              ))}
            </Box>
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OffersPage;
